<script>
    import axios from 'axios';

    export default {
        data: () => ({
            likes: 0,
            current: {},
            response: {},
            fielType: '',
        }),     
        methods: {
            async getNew (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL + 'detalle-noticia?id_noticia=' + this.$route.params.id);
                    this.current = res.data
                    this.$refs.videoRef.src = this.current.video;
                    this.fielType = this.current.imagen.split('.').pop()
                    this.likes = this.current.me_gusta
                } catch (err) { console.log(err) }
            },
            async like(id){
                try{
                    const resa = await axios.post(process.env.VUE_APP_SERVICE_URL + 'me-gusta-noticia?id=' + id);
                    this.likes = resa.data.numero
                    
                    
                } catch (err) { console.log(err) }
            },
            buscando(){
                var info = document.getElementById("info-c");
                var span_inco = info.getElementsByTagName("p");
                var span = info.getElementsByTagName("span");
 
                for (let item of span_inco) {
                    item.style.color = "";
                }
                for (let item of span) {
                    item.style.color = "";
                }
            }
        },
        mounted() {
            this.getNew();

            this.trackLog({ 
                action: 'Ingreso a detalle de noticia', 
                medio: 'Banner', 
                sectionId: 2, 
                elementId: this.$route.params.id, 
            })

            //setTimeout(function () { this.buscando(); }.bind(this), 100)
        }
    }
</script>

<template>
    <div class="new-detail-view">
        <div class="container">

            <!--<div class="relative mb-5" v-if="fielType === 'mp4'">-->
            <div class="relative mb-5">
                <video ref="videoRef" :poster="current.poster" class="rounded-10 border-1" controls>
                    <source :src="current.video" type="video/mp4">
                    Your browser does not support HTML video.
                </video>
                <img class="decore-shine absolute l-0 r-0 b-0 -mb-2 mx-auto" src="@/assets/images/layout/shine-banner.png" alt="">
            </div>

            <div class="relative bg-center bg-cover bg-no-repeat h-55 w-100pr rounded-10 border-1 border-white mb-5" :style="{ 'background-image': 'url(' + current.poster + ')' }">
                <img class="decore-shine absolute l-0 r-0 b-0 -mb-2 mx-auto" src="@/assets/images/layout/shine-banner.png" alt="">
            </div>

            <p class="tx-20 tx-bold" v-html="current.titulo"></p>
            <!-- BODY -->
            <div class="tx-14 tx-medium mb-6" id="info-c"  v-html="current.descripcion"></div>
            <div>
                <span><img width="30" class="" src="@/assets/images/me_gusta.png" alt=""> {{this.likes}}</span><button class="btn like-button" @click="like(current.id)" >Me gusta</button>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>

    .like-button{
        margin:  15px;
        background: #3C0778;
        color: #fff;
    }
    .decore-shine{ max-width: initial;}
</style>
